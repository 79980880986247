<script setup>
import { JBanner, JBannerSceleton } from 'media-flow-ui'
import { toRefs, ref, onMounted } from 'vue'
import { storeToRefs } from 'pinia'
import { usePageBuilder } from '~/stores/page-builder'
import { useUrlBuilder } from '~/stores/url-builder'
import { $$t } from '@/utils/i19m'
const urlBuilder = useUrlBuilder()
const { getProjectId } = storeToRefs(urlBuilder)

import StaticBanner from '~~/models/layout/components/banner/StaticBanner'
import DynamicBanner from '~~/models/layout/components/banner/DynamicBanner'
import BannerStyle from '~~/models/layout/components/banner/BannerStyle'
import AnalyticsHandler from '~/models/analytics/handler/Handler'

let DOMPurify
if (process.client) {
  // Dynamically import DOMPurify and destructure the sanitize method for direct use
  DOMPurify = await import('dompurify').then(
    (module) => module.default || module
  )
}

const pageBuilderStore = usePageBuilder()
const { deviceType } = storeToRefs(pageBuilderStore)

const props = defineProps({
  data: {
    type: Object,
    required: false,
    default: false,
  },
  rowOrder: {
    type: Number,
    required: true,
  },
})

const showSceleton = ref(true)
const bannerData = ref({})
const bannerStyle = ref(new BannerStyle())
const vpAppsBanner = ref(null)
const analyticsHandler = ref(null)

const { path } = useRoute()

const demoBanner = ref({
  img: '',
  alt: '',
  imgTo: '',
  textTo: null,
  title: '',
  description:
    '',
  userEngagements: {},
  linkType: 2,
  wrapperName: 'div',
  socialMedia: { items: [] },
  isRichText: false,
  videoSrc: null,
  autostart: false,
  canControlAudio: false,
  buttons: [],
})

const { data } = toRefs(props)

const style = new BannerStyle()
style.fromQuery(data.value?.style)
bannerStyle.value = style

function sanitizeHtml(html) {
  if (DOMPurify && typeof DOMPurify.sanitize === 'function') {
    const cleanText = DOMPurify.sanitize(html, { ALLOWED_TAGS: [] })
    return `<p>${cleanText}</p>`
  }
  return html
}

await useAsyncData(async () => {
  if (data.value.props.isDynamic) {
    const queryId = data.value.props.settings?.query.id
    const contentId =
      data.value.props.settings?.contentId ||
      data.value.props.settings?.groupInstanceId
    const params = {
      take: 1,
    }

    const banner = new DynamicBanner()

    let response
    const res = await pageBuilderStore.actionGetQueryDataInitClient(
      queryId,
      params,
      contentId,
      false,
      props.rowOrder
    )

    if (res) {
      response = res.response
    }

    if (response && response !== -1) {
      banner.fromQuery(response.data[0], data.value.props, deviceType.value)
      bannerData.value = {
        ...banner,
        showMoreButtonText: $$t('component_details_show_more'),
        showLessButtonText: $$t('component_details_show_less'),
      }
      showSceleton.value = false
    }
  } else {
    const banner = new StaticBanner()
    banner.fromQuery(data.value)
    bannerData.value = {
      ...banner,
      showMoreButtonText: $$t('component_details_show_more'),
      showLessButtonText: $$t('component_details_show_less'),
    }
    showSceleton.value = false
  }
})

const getAnalyticsContent = (event) => {
  const { img, title, description, videoSrc } = bannerData.value
  const content = {
    title,
    description,
    media: img || videoSrc || '',
    rowIndex: props.rowOrder,
    event: event,
  }

  return content
}

function swapItemsJustifyClasses(classString) {
  return classString
    .split(' ')
    .map((cls) => {
      if (cls.startsWith('items-')) {
        return cls.replace('items-', 'justify-')
      } else if (cls.startsWith('justify-')) {
        return cls.replace('justify-', 'items-')
      }
      return cls
    })
    .join(' ')
}

const onBannerClick = () => {
  const content = getAnalyticsContent('BannerClick')
  analyticsHandler.value.sendBannerClick(content)
}

const onBannerView = () => {
  const content = getAnalyticsContent('BannerView')
  analyticsHandler.value.sendBannerView(content)
}

onMounted(() => {
  analyticsHandler.value = new AnalyticsHandler()
  useIsElementOnView(vpAppsBanner.value, onBannerView, true)
})
</script>

<template>
  <div class="h-full w-full" ref="vpAppsBanner" @click="onBannerClick">
    <!-- <pre class="text-red-200"></pre> -->

    <JBannerSceleton
      v-show="showSceleton"
      :data="{
        ...bannerData,
        description: sanitizeHtml(bannerData.description),
      }"
      :has-overlay="bannerStyle?.imageStyle?.blur > 0 ? true : false"
      :custom-wrapper="demoBanner?.wrapperName"
      :title-style="bannerStyle.titleStyle"
      :description-style="bannerStyle.descriptionStyle"
      :content-style="bannerStyle.contentStyle"
      :wrapper-style="bannerStyle.wrapperStyle"
      :content-classes="bannerStyle.contentClasses"
      :autoplay="demoBanner.autostart"
      :canControlAudio="demoBanner.canControlAudio"
      :buttons="demoBanner.buttons"
    />
    <JBanner
      :key="data.id"
      v-show="!showSceleton"
      :data="{
        ...bannerData,
        description: sanitizeHtml(bannerData.description),
      }"
      :has-overlay="bannerStyle?.imageStyle?.blur > 0 ? true : false"
      :custom-wrapper="bannerData.clickable ? defineNuxtLink({}) : 'div'"
      :title-style="bannerStyle.titleStyle"
      :description-style="bannerStyle.descriptionStyle"
      :content-style="bannerStyle.contentStyle"
      :wrapper-style="bannerStyle.wrapperStyle"
      :content-classes="bannerStyle.contentClasses"
      :button-alignment="swapItemsJustifyClasses(bannerStyle.contentClasses)"
      :autoplay="bannerData.autostart"
      :can-control-audio="bannerData.canControlAudio"
      :buttons="bannerData.buttons"
      :buttonCustomWrapper="defineNuxtLink({})"
    >
      <template #tonos-buttons>
        <div
          v-if="data?.props?.tonosButtons?.length"
          v-for="tonosButton in data.props.tonosButtons"
          class="z-[9999] flex h-fit w-full text-white"
          :class="swapItemsJustifyClasses(bannerStyle.contentClasses)"
        >
          <TonosComponent :data="tonosButton" />
        </div>
      </template>
    </JBanner>
  </div>
</template>
